export const STATUS = [
  {
    key: 'pending',
    label: 'ยังไม่ให้คะแนน/รีวิว',
  },
  {
    key: 'contact_later',
    label: 'ให้โทรกลับภายหลัง',
  },
  {
    key: 'unable_to_contact',
    label: 'ไม่รับสาย/ติดต่อลูกค้าไม่ได้',
  },
  {
    key: 'not_interested',
    label: 'ไม่สนใจตอบ/ไม่สะดวกตอบ',
  },
  {
    key: 'contacted',
    label: 'ติดต่อแล้ว ลูกค้าจะไปทำเอง',
  },
  {
    key: 'completed',
    label: 'สำเร็จ',
  },
  {
    key: 'completed_by_cs',
    label: 'สำเร็จ โดยCS',
  },
]

export const TYPE = [
  {
    value: 'original',
    label: 'Local',
  },
  {
    value: 'bigbrand',
    label: 'Inter',
  }
]
