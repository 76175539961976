import axios from 'axios'
import React from 'react'
import { Dropdown, Button, Icon, Menu } from 'antd'

import { Table as AntdTable } from 'antd'
import { SurveyBooking } from './interface'
import { API_DRIVEHUB_HOST } from '../../../../config'
import { IAuth } from '../../../../interfaces'

const drivehubAxios = axios.create({ baseURL: API_DRIVEHUB_HOST })

interface IProps {
  bookings: SurveyBooking[];
}

interface IState {
  bookings: SurveyBooking[];
}

class Table extends React.Component<IProps, IState> {
  state = {
    bookings: this.props.bookings
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.bookings !== this.props.bookings) {
      this.setState({ bookings: this.props.bookings })
    }
  }
 
  handleOnMenuClick = (value: any, row: SurveyBooking) => {
    const bookingID = row.booking_id
    const action = value.key

    this.state.bookings
      .filter(booking => booking.booking_id === bookingID)
      .map(booking => {
        booking.approve_status = action
        booking.is_editing = (action !== booking.original_approve_status)
        return booking
      })

    this.setState({ bookings: this.state.bookings })
  }

  handleOnSaveClick = (row: SurveyBooking) => {
    if (row.original_approve_status === 'pending') {
      this.createSurveyBookingApprove(row)
    } else {
      this.updateSurveyBookingApprove(row)
    }
  }

  getAdminInfo = () => {
    const authStr = localStorage.getItem('auth')
    let admin = {} as IAuth

    if (authStr) {
      admin = JSON.parse(authStr);
      return admin
    }

    return null
  }

  responseBookingApprove = (row: SurveyBooking) => {
    this.state.bookings
      .filter(booking => booking.booking_id === row.booking_id)
      .map(booking => {
        booking.is_editing = false
        booking.original_approve_status = row.approve_status
        return booking
      })
        
    this.setState({ bookings: this.state.bookings })
  }

  createSurveyBookingApprove = (row: SurveyBooking) => {
    const path = '/api/v1/th/surveys_booking_approve'
    const admin = this.getAdminInfo()
    const approveResult = row.approve_status === 'approved'

    if (admin) {
      drivehubAxios.post(path, {
        admin_drivehub_id: admin.id,
        admin_email: admin.email,
        admin_role: admin.role,
        approve: approveResult,
        booking_id: row.booking_id
      }).then((_) => {
        this.responseBookingApprove(row)
      }).catch(error => {
        console.log(error)
      })
    }
  }

  updateSurveyBookingApprove = (row: SurveyBooking) => {
    const path = '/api/v1/th/surveys_booking_approve/' + row.booking_id
    const admin = this.getAdminInfo()
    const approveResult = row.approve_status === 'approved'

    if (admin) {
      drivehubAxios.put(path, {
        admin_drivehub_id: admin.id,
        admin_email: admin.email,
        admin_role: admin.role,
        approve: approveResult,
        booking_id: row.booking_id
      }).then((_) => {
        this.responseBookingApprove(row)
      }).catch(error => {
        console.log(error)
      })
    }
  }

  statusDisplay = (status: string) => {
    switch (status) {
      case "pending": return "Pending"
      case "approved": return "Approved"
      case "not_approved": return "Not Approved"
    }
  }
  
  status = (row: SurveyBooking) => (
    <Menu onClick={value => this.handleOnMenuClick(value, row)}>
      <Menu.Item key="approved">Approved</Menu.Item>
      <Menu.Item key="not_approved">Not Approved</Menu.Item>
    </Menu>
  )

  columns = [
    {
      title: "Booking no.",
      dataIndex: "booking_id",
      width: 120,
      sorter: (a: SurveyBooking, b: SurveyBooking) => a.booking_id - b.booking_id
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      width: 120,
      sorter: (a: SurveyBooking, b: SurveyBooking) => a.booking_id - b.booking_id
    },
    {
      title: "Updated at",
      dataIndex: "updated_at",
      ket: "updated_at",
      sorter: (a: SurveyBooking, b: SurveyBooking) => a.updated_at.localeCompare(b.updated_at)
    },
    {
      title: "Action",
      dataIndex: "approve_status",
      key: "approve_status",
      render: (value: string, row: SurveyBooking) => {
        return (
          <Dropdown overlay={this.status(row)}>
            <Button>
              {this.statusDisplay(value)} <Icon type="down" />
            </Button>
          </Dropdown>
        )
      },
      sorter: (a: SurveyBooking, b: SurveyBooking) => a.approve_status.localeCompare(b.approve_status)
    },
    {
      title: "Name",
      dataIndex: "customer.first_name",
      key: "customer.first_name",
      render: (value: string, row: SurveyBooking, _index: number) => value + " " + row.customer.last_name
    },
    {
      title: "Telephone",
      dataIndex: "customer.phone_number",
      key: "customer.phone_number",
      render: (value: string) => value.replace('+66', '0'),
      sorter: (a: SurveyBooking, b: SurveyBooking) => a.customer.phone_number.localeCompare(b.customer.phone_number)
    },
    {
      title: "Brand model",
      dataIndex: "car_title",
      key: "car_title",
      width: 120,
      sorter: (a: SurveyBooking, b: SurveyBooking) => a.car_title.localeCompare(b.car_title)
    },
    {
      title: "Dealer",
      dataIndex: "dealer_name",
      key: "dealer_name",
      sorter: (a: SurveyBooking, b: SurveyBooking) => a.dealer_name.localeCompare(b.dealer_name)
    },
    {
      title: "Branch",
      dataIndex: "branch_name",
      key: "branch_name",
      sorter: (a: SurveyBooking, b: SurveyBooking) => a.branch_name.localeCompare(b.branch_name)
    },
    {
      title: "Pickup date",
      dataIndex: "pickup_datetime",
      key: "pickup_datetime",
      sorter: (a: SurveyBooking, b: SurveyBooking) => a.pickup_datetime.localeCompare(b.pickup_datetime)
    },
    {
      title: "Return date",
      dataIndex: "return_datetime",
      key: "return_datetime",
      sorter: (a: SurveyBooking, b: SurveyBooking) => a.return_datetime.localeCompare(b.return_datetime)
    },
    {
      title: "Location",
      dataIndex: "location_name",
      key: "location_name",
      sorter: (a: SurveyBooking, b: SurveyBooking) => a.location_name.localeCompare(b.location_name)
    },
    {
      title: "Answer",
      dataIndex: "answer",
      key: "answer"
    },
    {
      title: 'Save',
      dataIndex: 'is_editing',
      render: (value: boolean, row: SurveyBooking) => {
        return (
          <Button
            icon="save"
            type="primary"
            onClick={() => {
              this.handleOnSaveClick(row)
            }}
            disabled={!value}
          ></Button>
        )
      }
    }
  ]

  render() {
    return (
      <AntdTable 
        dataSource={this.state.bookings}
        columns={this.columns}
        pagination={{ pageSize: 15, position: 'both' }}
        size={"middle"}
        rowKey="booking_id"
      />
    )
  }
}

export default Table
