import React, { Component } from 'react'
import styled from 'styled-components'
import moment, { Moment } from 'moment'
import axios from 'axios'
import {
  DatePicker,
  Select,
  Table,
  Row,
  Col,
  Button,
  Checkbox,
} from 'antd'
import { STATUS, TYPE } from './const'

const { Option } = Select
const { MonthPicker } = DatePicker
const dateFormat = 'YYYY/MM'

interface Booking {
  id: number;
  booking_id: number;
  updated_at: string;
  status: string;
  survey_id: number;
  survey_url: string;
  customer_name: string;
  customer_phonenumber: string;
  car_type: string;
  dealer_name: string;
  branch_name: string;
  pickup_datetime: string;
  return_datetime: string;
  location: string;
}

interface State {
  actions: string[];
  year: string;
  month: string;
  list: Booking[];
  selectedDate: Moment;
  loading: boolean;
  bookingTypes: string[];
  activeButtons: number[];
}

interface Props {
  className?: string;
}

class RatingCSDashboard extends Component<Props, State> {
  state = {
    actions: ['pending', 'contact_later', 'unable_to_contact'],
    year: String(moment().year()),
    month: moment().format('MM'),
    list: [],
    selectedDate: moment(),
    loading: false,
    bookingTypes: ['original', 'bigbrand'],
    activeButtons: [],
  }

  componentDidMount() {
    this.getSurveys()
  }

  getSurveys = () => {
    this.setState({ loading: true })
    axios
      .get('/api/v1/surveys', {
        params: {
          status: this.state.actions.join(','),
          year: this.state.year,
          month: this.state.month,
          source: this.state.bookingTypes.join(','),
        },
      })
      .then(({ data }) => {
        this.setState({ list: data, loading: false })
      })
  }

  updateSurveyStatus = (row: any) => {
    this.setState({ loading: true })
    axios
      .put(`/api/v1/surveys/${row.booking_id}`, {
        status: row.status,
        source: row.source,
      })
      .then(() => {
        const rowId: number = row.id
        const activeButtons: number[] = this.state.activeButtons
        const updatedActiveButton: number[] = activeButtons.filter(
          id => id !== rowId,
        )
        this.setState({ loading: false, activeButtons: updatedActiveButton })
      })
      .catch(error => {
        alert('มีข้อผิดพลาดเกิดขึ้น กรุณาลองใหม่อีกครั้ง')
        console.log('Cannot update status:', error)
      })
  }

  onChangeCSAction = (value: any) => {
    this.setState({ actions: value }, () => {
      this.getSurveys()
    })
  }

  onChangeYearMonth = (date: Moment | null) => {
    if (date == null) {
      return
    }
    this.setState(
      {
        selectedDate: date,
        year: String(date.year()),
        month: date.format('MM'),
      },
      () => {
        this.getSurveys()
      },
    )
  }

  onChooseType = (value: any) => {
    this.setState({ bookingTypes: value }, () => {
      this.getSurveys()
    })
  }

  get columns() {
    return [
      {
        title: 'Booking no.',
        dataIndex: 'booking_id',
        sorter: (a: any, b: any) => a.booking_id - b.booking_id,
      },
      {
        title: 'Updated at',
        dataIndex: 'updated_at',
        render: (r: any) => moment(r).format('YYYY/MM/DD HH:mm'),
        sorter: (a: any, b: any) => {
          const dateA: any = new Date(a.updated_at)
          const dateB: any = new Date(b.updated_at)
          return dateA - dateB
        },
      },
      {
        title: 'CS Action',
        dataIndex: 'status',
        render: (r: any, record: any) => (
          <Select
            defaultValue={r}
            onChange={(value: any) => {
              record.status = value
              const activeButtons: any = this.state.activeButtons
              this.setState({ activeButtons: [...activeButtons, record.id] })
            }}
            style={{ width: '100%' }}
            className="cs-action-select"
          >
            {STATUS.map(status => {
              return <Option key={status.key}>{status.label}</Option>
            })}
          </Select>
        ),
        sorter: (a: any, b: any) => a.status.length - b.status.length,
      },
      {
        title: 'Link to Survey',
        dataIndex: 'survey_url',
        render: (r: any) => {
          if (r) return <a href={r}>Link</a>
          else return '-'
        },
      },
      {
        title: 'Customer name',
        dataIndex: 'customer_name',
      },
      {
        title: 'Telephone',
        dataIndex: 'customer_phone_number',
      },
      {
        title: 'Brand Model',
        dataIndex: 'car_title',
      },
      {
        title: 'Dealer',
        dataIndex: 'dealer_name',
        sorter: (a: any, b: any) => a.dealer_name.length - b.dealer_name.length,
      },
      {
        title: 'Branch',
        dataIndex: 'branch_name',
        sorter: (a: any, b: any) => a.branch_name.length - b.branch_name.length,
      },
      {
        title: 'Pickup date',
        dataIndex: 'pickup_datetime',
        render: (r: any) => moment(r).format('YYYY/MM/DD HH:mm'),
        sorter: (a: any, b: any) => {
          const dateA: any = new Date(a.pickup_datetime)
          const dateB: any = new Date(b.pickup_datetime)
          return dateA - dateB
        },
      },
      {
        title: 'Return date',
        dataIndex: 'return_datetime',
        render: (r: any) => moment(r).format('YYYY/MM/DD HH:mm'),
        sorter: (a: any, b: any) => {
          const dateA: any = new Date(a.return_datetime)
          const dateB: any = new Date(b.return_datetime)
          return dateA - dateB
        },
      },
      {
        title: 'Location',
        dataIndex: 'location_name',
      },
      {
        title: 'Save',
        dataIndex: 'id',
        render: (r: any, record: any) => {
          const id: number = record.id
          const activeArray: number[] = this.state.activeButtons
          const is_edit: boolean = activeArray.includes(id)
          return (
            <Button
              icon="save"
              type="primary"
              onClick={() => {
                this.updateSurveyStatus(record)
              }}
              disabled={!is_edit}
            ></Button>
          )
        },
      },
    ]
  }

  render() {
    return (
      <div className={this.props.className}>
        <div className="dashboard__cs">
          <h1>CS Rating & Review Dashboard</h1>
          <Row>
            <Col span={4}>
              <span>Filter Action</span>
            </Col>
            <Col span={8}>
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Please select"
                defaultValue={['pending', 'contact_later', 'unable_to_contact']}
                onChange={this.onChangeCSAction}
              >
                {STATUS.map(status => {
                  return <Option key={status.key}>{status.label}</Option>
                })}
              </Select>
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <span>Filter ประเภท booking</span>
            </Col>
            <Col span={4}>
              <Checkbox.Group
                options={TYPE}
                defaultValue={['original', 'bigbrand']}
                onChange={this.onChooseType}
              />
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <span>Filter เดือนที่มีการ Update</span>
            </Col>
            <Col span={8}>
              <MonthPicker
                value={this.state.selectedDate}
                format={dateFormat}
                onChange={date => this.onChangeYearMonth(date)}
              />
            </Col>
          </Row>
          <Row className="table-container">
            <Table
              columns={this.columns}
              dataSource={this.state.list}
              rowKey="id"
              pagination={{ pageSize: 15 }}
              loading={this.state.loading}
              size={'middle'}
            />
          </Row>
        </div>
      </div>
    )
  }
}

export default styled(RatingCSDashboard)`
  .dashboard__cs {
    padding: 30px;
  }
  .ant-row {
    padding: 10px 0;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 12px 8px;
  }
  .cs-action-select {
    min-width: 160px;
  }
`
