import axios from 'axios'
import React from 'react'
import moment, { Moment } from 'moment'
import styled from 'styled-components'
import { DatePicker } from 'antd'

import TableSummary from './table-summary'
import TableBookings from './table-bookings'
import { IBranchRating } from './interface'

const { MonthPicker } = DatePicker

const currentDate = moment()
const dateFormat = 'YYYY/MM'

interface IState {
  branchID: string;
  selectedDate: Moment;
  ratingBranch: IBranchRating;
}
class DashboardBranch extends React.Component<any, IState> {
  state = {
    branchID: '0',
    selectedDate: currentDate,
    ratingBranch: {
      dealer_name: '',
      branch_name: '',
      summary: [],
      bookings: [],
    },
  }

  componentDidMount() {
    const branchID = this.props.match.params.branch_id
    this.setState({ branchID }, () => {
      this.getRatingBranch()
    })
  }

  handleOnChange = (date: Moment | null) => {
    if (date == null) {
      return
    }
    this.setState({ selectedDate: date }, () => {
      this.getRatingBranch()
    })
  }

  getRatingBranch = () => {
    const year = this.state.selectedDate.format('YYYY')
    const month = this.state.selectedDate.format('MM')

    axios
      .get(`/api/v1/rating_branch?branch_id=${this.state.branchID}&year=${year}&month=${month}`)
      .then(({ data }) => {
        this.setState({ ratingBranch: data })
      })
  }

  render() {
    return (
      <Wrapper>
        <div className="dashboard__branch">
          <h1>Branch Rating & Review Dashboard</h1>
          <h3>
            <span>Dealer name: {this.state.ratingBranch.dealer_name}</span>
          </h3>
          <h3>
            <span>Branch name: {this.state.ratingBranch.branch_name}</span>
          </h3>
          <TableSummary summary={this.state.ratingBranch.summary} />
          <div className="calendar__wrapper">
            <span>ค้นหาเดือนที่คืนรถ</span>
            <MonthPicker
              value={this.state.selectedDate}
              format={dateFormat}
              onChange={date => this.handleOnChange(date)}
            />
          </div>
          <TableBookings bookings={this.state.ratingBranch.bookings} />
        </div>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  .dashboard__branch {
    padding: 30px;

    .calendar__wrapper {
      margin-top: 15px;

      span {
        margin-right: 10px;
      }
    }
  }
`

export default DashboardBranch
