import React from 'react'
import styled from 'styled-components'
import { Table as AntdTable } from 'antd'

import { ISummary } from './interface'

interface IProps {
  summary: ISummary[]
}

class TableSummary extends React.Component<IProps, {}> {
  get columns() {
    return [
      {
        title: '',
        dataIndex: '',
        key: '',
        className: '',
        render: (_value: any, _row: any, index: number) => {
          if (this.props.summary[index].title === '') {
            return ''
          }
          return `ข้อ ${index + 1}`
        },
      },
      {
        title: '',
        dataIndex: 'title',
        key: 'title',
        render: (value: string, _row: any, index: number) => {
          if (this.props.summary[index].title === '') {
            return <div className="text_right">AVG</div>
          }
          return value
        },
      },
      {
        title: 'Score (max 5)',
        dataIndex: 'score',
        key: 'score',
        className: 'col__score',
      },
    ]
  }

  render() {
    return (
      <Wrapper>
        <AntdTable
          columns={this.columns}
          dataSource={this.props.summary.map(s => ({
            ...s,
            key: s.title
          }))}
          size={'small'}
          pagination={false}
        />
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  .ant-table-wrapper {
    width: 500px;
    margin-top: 20px;
    .ant-table-body {
      margin: 0px;
    }
  }

  .col__score {
    text-align: center;
    width: 25%;
    background-color: #d6eaf8;
  }

  .text_right {
    text-align: right;
    font-weight: bold;
  }
`

export default TableSummary
