type status = 'pending' | 'approved' | 'not_approved'

export interface SurveyBooking {
  booking_id: number;
  source: string;
  updated_at: string;
  approve_status: status;
  original_approve_status: status;
  survey_url: string;
  customer: Customer;
  car_title: string;
  dealer_name: string;
  branch_name: string;
  pickup_datetime: string;
  return_datetime: string;
  location_name: string;
  answer: string;
  is_editing: boolean;
}

export interface Customer {
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  note: string;
}
export enum SearchType {
  Dealer = 'dealer',
  Branch = 'branch',
  FirstName = 'firstName',
}

export interface SearchActive {
  value: string
  type: SearchType
}