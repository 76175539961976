import React from 'react'
import { Form, Icon, Input, Button } from 'antd'
import queryString from 'query-string'
import axios from 'axios'
import styled from 'styled-components'
import { API_HOST } from '../config'

axios.defaults.baseURL = API_HOST

class Login extends React.PureComponent {
  state = {
    email: '',
    password: '',
    error: null,
    isLoading: false,
  }
  onSubmit = (event: any) => {
    event.preventDefault()
    this.setState({ isLoading: true })

    axios.post('/auth/login', {
      email: this.state.email,
      password: this.state.password
    })
      .then(({ data }) => {
        this.setState({ isLoading: false, error: null })
        const query = queryString.parse(window.location.search)
        const next = query.next || '/'
        localStorage.setItem('auth', JSON.stringify(data))
        if (typeof next === 'string') {
          window.location.replace(next)
        } else {
          window.location.replace('/')
        }
      })
      .catch(err => {
        const data = err.response.data
        this.setState({ isLoading: false, error: data.message })
      })
  }
  render() {
    return (
      <Wrapper>
        <h3>SIGN IN</h3>
        <form onSubmit={this.onSubmit} className="login-form">
          <Form.Item>
            <Input
              value={this.state.email}
              onChange={e => this.setState({ email: e.target.value })}
              prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item>
            <Input
              value={this.state.password}
              onChange={e => this.setState({ password: e.target.value })}
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            {this.state.error && <div className="error">{this.state.error}</div>}
            <Button
              loading={this.state.isLoading}
              type="primary" htmlType="submit" className="login-form-button">
              Log in
            </Button>
          </Form.Item>
        </form>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
h3 {
  margin-top: 48px;
  margin-bottom: 16px;
}
.error {
  color: red;
}
.login-form {
  width: 100%;
  max-width: 400px;
}
.ant-form-item {
  margin-bottom: 0px;
}
.login-form-button {
  width: 100%;
}`

export default Login
