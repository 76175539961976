import React from 'react'
import styled from 'styled-components'
import { Input } from 'antd'
import { SearchType } from './interface'
interface SearchBoxProps {
  onChangeSearch: (value: string, type: SearchType) => void;
}
const SearchBox: React.FC<SearchBoxProps> = props => {
  return (
    <SearchBoxWrapper>
      <div className="search-box">
        <div className="search-box__item">
          <h3>Dealer</h3>
          <Input.Search
            onChange={e =>
              props.onChangeSearch(e.target.value, SearchType.Dealer)
            }
            placeholder="Dealer"
            enterButton
            size="large"
            onSearch={value => props.onChangeSearch(value, SearchType.Dealer)}
          />
        </div>
        <div className="search-box__item">
          <h3>Branch</h3>
          <Input.Search
            onChange={e =>
              props.onChangeSearch(e.target.value, SearchType.Branch)
            }
            placeholder="Branch"
            enterButton
            size="large"
            onSearch={value => props.onChangeSearch(value, SearchType.Branch)}
          />
        </div>
        <div className="search-box__item">
          <h3>First Name</h3>
          <Input.Search
            onChange={e =>
              props.onChangeSearch(e.target.value, SearchType.FirstName)
            }
            placeholder="firstName"
            enterButton
            size="large"
            onSearch={value =>
              props.onChangeSearch(value, SearchType.FirstName)
            }
          />
        </div>
      </div>
    </SearchBoxWrapper>
  )
}
const SearchBoxWrapper = styled.div`
  .search-box {
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    &__item {
      padding-right: 16px;
    }
  }
`
export default SearchBox
