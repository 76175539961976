import React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { Table as AntdTable } from 'antd'

import { IBooking } from './interface'

type align = 'left' | 'right' | 'center'
const dateFormat = 'YYYY/MM/DD HH:mm'

interface IProps {
  bookings: IBooking[]
}

class TableBookings extends React.Component<IProps, {}> {
  get columns() {
    return [
      {
        title: 'Booking no.',
        dataIndex: 'booking_id',
        width: 100,
        key: 'booking_id'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        align: 'center' as align,
        key: 'staus',
        render: (status: string) => {
          if (status === "Bad") {
            return <div className="column__bad">{status}</div>
          }
          return status
        }
      },
      {
        title: 'Pickup date',
        dataIndex: 'pickup_datetime',
        key: 'pickup',
        render: (pickup_datetime: string) => {
          return moment(pickup_datetime).format(dateFormat)
        }
      },
      {
        title: 'Return date',
        dataIndex: 'return_datetime',
        key: 'return',
        render: (return_datetime: string) => {
          return moment(return_datetime).format(dateFormat)
        }
      },
      { title: 'Car title', dataIndex: 'car_title', key: 'title' },
      {
        title: 'ข้อ 1',
        dataIndex: 'summary[0].score',
        align: 'center' as align,
        key: '1'
      },
      {
        title: 'ข้อ 2',
        dataIndex: 'summary[1].score',
        align: 'center' as align,
        key: '2'
      },
      {
        title: 'ข้อ 3',
        dataIndex: 'summary[2].score',
        align: 'center' as align,
        key: '3'
      },
      {
        title: 'ข้อ 4',
        dataIndex: 'summary[3].score',
        align: 'center' as align,
        key: '4'
      },
      {
        title: 'ข้อ 5',
        dataIndex: 'summary[4].score',
        align: 'center' as align,
        key: '5'
      },
      {
        title: 'ข้อ 6',
        dataIndex: 'summary[5].score',
        align: 'center' as align,
        key: '6'
      },
      {
        title: 'รวม',
        dataIndex: 'total',
        align: 'center' as align,
        key: 'total'
      },
      { title: 'Comment', dataIndex: 'comment', key: 'comment' },
    ]
  }

  render() {
    return (
      <Wrapper>
        <div>
          <AntdTable
            columns={this.columns}
            dataSource={this.props.bookings.map(booking => ({
              ...booking,
              key: booking.booking_id,
            }))}
            pagination={{ pageSize: 15 }}
            size={'middle'}
          />
        </div>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  .ant-table-wrapper {
    margin-top: 20px;
  }

  .column__bad {
    background-color: #ff6347;
    color: white;
  }
`

export default TableBookings
