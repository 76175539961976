import React from 'react'
import axios from 'axios'
import { Route, BrowserRouter, Redirect } from 'react-router-dom'
import 'antd/dist/antd.css'
import moment from 'moment'
import * as config from './config'
import Home from './pages/home'
import Login from './pages/login'
import Booking from './pages/bookings'
import DashboardCS from './pages/dashborad/cs'
import DashboardMS from './pages/dashborad/ms'
import DashboardApprove from './pages/dashborad/cs/approve'
import DashboardBranch from './pages/dashborad/ms/bookings'
import { IAuth } from './interfaces'

axios.defaults.baseURL = config.API_HOST
const App: React.FC = () => {
  return (
    <div>
      <BrowserRouter>
        <AuthRoute exact path="/" component={Home} />
        <Route path="/login" component={Login} />
        <AuthRoute exact path="/bookings" component={Booking} />
        <AuthRoute exact path="/dashboard_cs" component={DashboardCS} />
        <AuthRoute exact path="/dashboard_cs/approve" component={DashboardApprove} />
        <AuthRoute exact path="/dashboard_ms" component={DashboardMS} />
        <AuthRoute exact path="/dashboard_ms/branches/:branch_id" component={DashboardBranch} />
      </BrowserRouter>
    </div>
  )
}


const AuthRoute = (props: any) => {
  const authStr = localStorage.getItem('auth')
  let auth = {} as IAuth
  let isAuthenticated = false
  if (authStr) {
    auth = JSON.parse(authStr);
    if (moment().isAfter(auth.expired_at)) {
      isAuthenticated = false
      localStorage.removeItem('auth')
    } else {
      axios.defaults.headers = {
        token: auth.token
      }
      isAuthenticated = true
    }
  }
  const Component = props.component
  const nextPath = window.location.href

  return (
    <Route
      exact={props.exact}
      path={props.path}
      render={p => {
        return isAuthenticated ? <Component {...props} {...p} auth={auth} /> : <Redirect
          to={`/login?next=${nextPath}`}
        />
      }}
    />
  );
}
export default App
