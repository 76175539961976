import axios from 'axios'
import React from 'react'
import moment, { Moment } from 'moment'
import styled from 'styled-components'
import { Input, DatePicker, Dropdown, Menu, Button, Icon } from 'antd'
import { ClickParam } from 'antd/lib/menu'

import Table from './table'
import { BranchRatingSummary } from './interface'

const { MonthPicker } = DatePicker
const { Search } = Input

const currentDate = moment()
const dateFormat = 'YYYY/MM'

type source = 'original' | 'bigbrand'

interface IState {
  source: source;
  search: string;
  selectedDate: Moment;
  branches: BranchRatingSummary[];
}

class DashboardMS extends React.Component<{}, IState> {
  state = {
    source: 'original' as source,
    search: '',
    selectedDate: currentDate,
    branches: [],
  }

  componentDidMount() {
    this.getBranches()
  }

  handleOnChange = (date: Moment | null) => {
    if (date == null) {
      return
    }
    this.setState({ selectedDate: date }, () => {
      this.getBranches()
    })
  }

  handleOnSearch = (value: string) => {
    this.setState({ search: value }, () => {
      this.getBranches()
    })
  }

  handleOnMenuClick = (value: ClickParam) => {
    this.setState({ source: value.key as source }, () => {
      this.getBranches()
    })
  }

  getBranches = () => {
    const source = this.state.source
    const search = this.state.search
    const year= this.state.selectedDate.format('YYYY')
    const month = this.state.selectedDate.format('MM')

    axios
      .get(`/api/v1/rating_summary?source=${source}&year=${year}&month=${month}&search=${search}`)
      .then(({ data }) => this.setState({ branches: data }))
  }

  dropdownTitle = () => {
    switch (this.state.source) {
      case "original": return "Local"
      case "bigbrand": return "Inter"
    }
  }

  menu = (
    <Menu onClick={value => this.handleOnMenuClick(value)}>
      <Menu.Item key="original">Local</Menu.Item>
      <Menu.Item key="bigbrand">Inter</Menu.Item>
    </Menu>
  );

  render() {
    return (
      <Wrapper>
        <div className="dashboard__ms">
          <h1>Rating Summary Dashboard</h1>
          <div className="search__wrapper">
            <Dropdown overlay={this.menu} trigger={['click']}>
              <Button>
                {this.dropdownTitle()} <Icon type="down" />
              </Button>
            </Dropdown>
            <Search
              placeholder="ค้นหาด้วย ชื่อดีลเลอร์ หรือ ชื่อสาขา"
              onSearch={value => this.handleOnSearch(value)}
              enterButton
            />
            <div className="calendar__wrapper">
              <span>ค้นหาเดือนที่คืนรถ</span>
              <MonthPicker
                value={this.state.selectedDate}
                format={dateFormat}
                onChange={date => this.handleOnChange(date)}
              />
            </div>
          </div>
          <div className="table__wrapper">
            <Table branches={this.state.branches} />
          </div>
        </div>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  .dashboard__ms {
    padding: 30px;
    .ant-input-group-wrapper {
      margin-right: 150px;
      padding-right: 30px;
    }

    .search__wrapper {
      display: flax;

      .ant-dropdown-trigger {
        display: flax;
        margin-right: 30px;
      }

      .calendar__wrapper {
        span {
          margin-right: 30px;
        }
      }
    }

    .table__wrapper {
      margin-top: 20px;
    }
  }
`

export default DashboardMS
