import axios from 'axios'
import moment, { Moment } from 'moment'
import React from 'react'
import styled from 'styled-components'
import * as _ from 'lodash'
import { Dropdown, Button, Icon, Menu } from 'antd'

import Table from './table'
import { SurveyBooking, SearchType, SearchActive } from './interface'
import { API_DRIVEHUB_HOST } from '../../../../config'
import { ClickParam } from 'antd/lib/menu'
import SearchBox from "./search-box";
const currentDate = moment()

const drivehubAxios = axios.create({ baseURL: API_DRIVEHUB_HOST })

type source = 'all' | 'original' | 'bigbrand'
type status = 'all' | 'pending' | 'approved' | 'not_approved'
interface IState {
  source: source;
  status: status;
  selectedDate: Moment;
  bookings: SurveyBooking[];
  bookingsSearched: SurveyBooking[];
  isSearch: boolean;
  searchActive: SearchActive[]
}

class Approve extends React.Component<{}, IState> {
  state = {
    source: 'all' as source,
    status: 'all' as status,
    selectedDate: currentDate,
    bookings: new Array<SurveyBooking>(),
    bookingsSearched: new Array<SurveyBooking>(),
    isSearch: false,
    searchActive: new Array<SearchActive>()
  }

  componentDidMount() {
    this.getSurveyBookings()
  }

  handleOnSourceMenuClick = (value: ClickParam) => {
    this.setState({ source: value.key as source }, () => {
      this.getSurveyBookings()
    })
  }

  handleOnStatusMenuClick = (value: ClickParam) => {
    this.setState({ status: value.key as status }, () => {
      this.getSurveyBookings()
    })
  }

  sortByReturnDate = (bookings: Array<SurveyBooking>) => {
    return bookings.sort((a:SurveyBooking,b:SurveyBooking) => {
      return moment(b.return_datetime).valueOf() - moment(a.return_datetime).valueOf()
    })
  }

  removeDuplicate = (surveyBookings: Array<SurveyBooking>) => {
    return _.uniqWith(
      surveyBookings, 
      (bookingA: SurveyBooking, bookingB: SurveyBooking) => bookingA.booking_id === bookingB.booking_id
    )
  }

  getSurveyBookings = () => {
    var url = '/api/v1/th/surveys'
      + '?source=' 
      + this.state.source 
      + '&approve_status=' 
      + this.state.status

    drivehubAxios
      .get(url)
      .then(({ data }) => { 
        const surveyBookings = this.removeDuplicate(data["survey_bookings"])
        const sortedBookings = this.sortByReturnDate(surveyBookings)
        this.setState({ bookings: sortedBookings }, () => {
          if(this.state.searchActive.length <= 0) return
          this.state.searchActive.forEach(s => {
            this.handleSearch(s.value, s.type, this.state.bookings)
          })
        }) 
        
      }).catch(error => {
        console.log("[DRIVEHUB-API-LOG] Error: Get survey bookings api")
        console.log(error)
        this.setState({ bookings: [] }) 
      })
  }

  sourceDisplay = () => {
    switch (this.state.source) {
      case "all": return "All"
      case "original": return "Local"
      case "bigbrand": return "Inter"
    }
  }

  source = (
    <Menu onClick={value => this.handleOnSourceMenuClick(value)}>
      <Menu.Item key="all">All</Menu.Item>
      <Menu.Item key="original">Local</Menu.Item>
      <Menu.Item key="bigbrand">Inter</Menu.Item>
    </Menu>
  )

  statusDisplay = () => {
    switch (this.state.status) {
      case "all": return "All"
      case "pending": return "Pending"
      case "approved": return "Approved"
      case "not_approved": return "Not Approved"
    }
  }

  status = (
    <Menu onClick={value => this.handleOnStatusMenuClick(value)}>
      <Menu.Item key="all">All</Menu.Item>
      <Menu.Item key="pending">Pending</Menu.Item>
      <Menu.Item key="approved">Approved</Menu.Item>
      <Menu.Item key="not_approved">Not Approved</Menu.Item>
    </Menu>
  )
  
  handleSearch = (value: string, type:SearchType, source: SurveyBooking[]) => {
    switch(type) {
      case "dealer": {
        const newBookingState:SurveyBooking[] = source.filter(b => {
          if(b.dealer_name.toLowerCase().includes(value.toLowerCase())) {
            return b
          }
          return false
        })
        const sortedBookings = this.sortByReturnDate(newBookingState)
        return this.setState({bookingsSearched: sortedBookings})
      }
      case "branch": {
        const newBookingState:SurveyBooking[] = source.filter(b => {
          if(b.branch_name.toLowerCase().includes(value.toLowerCase())) {
            return b
          }
          return false
        })
        
        const sortedBookings = this.sortByReturnDate(newBookingState)
        return this.setState({bookingsSearched: sortedBookings})
      }
      case "firstName": {
        const newBookingState:SurveyBooking[] = source.filter(b => {
          if(b.customer.first_name.toLowerCase().includes(value.toLowerCase())) {
            return b
          }
          return false
        })
        const sortedBookings = this.sortByReturnDate(newBookingState)
        return this.setState({bookingsSearched: sortedBookings})
      }
      default: this.setState({bookingsSearched: this.state.bookings})
    }
  }

  onChangeSearch = (value: string, type:SearchType) => {
    if(this.state.searchActive.filter(el => el.type === type).length === 0) {
      return this.setState({searchActive: this.state.searchActive.concat({
        value: value,
        type: type
      }), isSearch: true}, () => {
        if(this.state.searchActive.length > 1) {
          return  this.setState({
            searchActive: this.state.searchActive.filter(el => el.type !== type).concat(
              {
                value: value,
                type: type
              }
          )}, () => {
            this.handleSearch(value, type, this.state.bookingsSearched)
          })
        }

        this.handleSearch(value, type, this.state.bookings)
      })
    }

    if(value === '') {
      return this.setState({searchActive: this.state.searchActive.filter(el => el.type !== type)}, () => {
        if(this.state.searchActive.length === 0) {
          return this.setState({isSearch: false }, ()=> {
            this.handleSearch(value, type, this.state.bookings)
          })
        }
        this.state.searchActive.forEach(s => {
            this.handleSearch(s.value, s.type, this.state.bookings)
        })
      })
    }
    
    if(this.state.searchActive.length > 1) {
      return     this.setState({
        searchActive: this.state.searchActive.filter(el => el.type !== type).concat(
          {
            value: value,
            type: type
          }
      )}, () => {
        this.handleSearch(value, type, this.state.bookingsSearched)
      })
    }

    this.setState({
      searchActive: this.state.searchActive.filter(el => el.type !== type).concat(
        {
          value: value,
          type: type
        }
    )}, () => {
      this.handleSearch(value, type, this.state.bookings)
    })
  }

  render() {
    const source = this.state.isSearch ? this.state.bookingsSearched: this.state.bookings
    return (
      <Wrapper>
        <div className="dashboard-approve">
          <h1>CS Rating & Review Dashboard [Approve]</h1>
          <div className="search-wrapper">
            {/* Start Filter Box */}
            <div className="filter-box">
              <div className="filter-box__item">
                <h3>Filter booking type:</h3>
                <Dropdown overlay={this.source}>
                  <Button>
                    {this.sourceDisplay()} <Icon type="down" />
                  </Button>
                </Dropdown>
              </div>

              <div className="filter-box__item">
                <h3>Filter action:</h3>
                <Dropdown overlay={this.status}>
                  <Button>
                    {this.statusDisplay()} <Icon type="down" />
                  </Button>
                </Dropdown>
              </div>
            </div>
            {/* End Filter Box */}
            {/* Start Search Box */}
            <SearchBox onChangeSearch={this.onChangeSearch}/>
            {/* End Search Box */}

          </div> {/* End search__wrapper */}

          <div className="table__wrapper">
            <Table bookings={source} />
          </div>
        </div>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  .dashboard-approve {
    padding: 30px;

    .search-wrapper {
      display: flex;
      flex-direction: column;
      .filter-box {
        margin-bottom: 16px;
        display: flex;
        flex-direction: row;
        @media screen and (max-width: 768px) {
          flex-direction: column;
        }
        &__item {
          display: flex;
          margin-right: 30px;
          @media screen and (max-width: 768px) {
            margin-bottom: 16px;
          }

          h3 {
            margin-right: 30px;
            margin-bottom: 0px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    .table__wrapper {
      margin-top: 30px;
      @media screen and (max-width: 768px) {
          .ant-table {
            overflow: scroll;
          }
        }
    }

  }
`

export default Approve