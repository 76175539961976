import React from 'react'
import Table from './table'
import { IAuth } from '../../interfaces';


interface IProps {
  auth: IAuth;
}

class BookingPage extends React.Component<IProps> {
  render() {
    return (
      <div>
        <Table
        />
      </div>
    )
  }
}


export default BookingPage