import React from "react";
import styled from "styled-components";
import { Table as AntdTable } from "antd";

import { BranchRatingSummary } from "./interface";

interface IProps {
  branches: BranchRatingSummary[];
}

type align = 'left' | 'right' | 'center'

const columns = [
  { title: "Dealer", 
    dataIndex: "dealer_name", 
    key: "dealer_name",
    sorter: (a: BranchRatingSummary, b: BranchRatingSummary) => a.dealer_name.localeCompare(b.dealer_name),
  },
  {
    title: "Branch",
    dataIndex: "branch_name",
    key: "branch_id",
    sorter: (a: BranchRatingSummary, b: BranchRatingSummary) => a.branch_name.localeCompare(b.branch_name),
    render: (text: string, row: any, _index: number) => <a href={"/dashboard_ms/branches/" + row.branch_id}>{text}</a>
  },
  {
    title: "คงเหลือยังไม่รีวิว",
    dataIndex: "not_submit_count",
    key: "not_submit_count",
    align: 'center' as align,
    sorter: (a: BranchRatingSummary, b: BranchRatingSummary) => a.not_submit_count - b.not_submit_count
  },
  {
    title: "รายการที่รีวิวแล้ว",
    dataIndex: "submitted_count",
    key: "submitted_count",
    align: 'center' as align,
    sorter: (a: BranchRatingSummary, b: BranchRatingSummary) => a.submitted_count - b.submitted_count
  },
  {
    title: "Bad (0.0-2.9)",
    dataIndex: "bad_count",
    key: "bad_count",
    width: "10%",
    align: 'center' as align,
    className: "column__bad",
    sorter: (a: BranchRatingSummary, b: BranchRatingSummary) => a.bad_count - b.bad_count
  },
  {
    title: "Medium (3.0-3.9)",
    dataIndex: "medium_count",
    key: "medium_count",
    width: "10%",
    align: 'center' as align,
    className: "column__medium",
    sorter: (a: BranchRatingSummary, b: BranchRatingSummary) => a.medium_count - b.medium_count
  },
  {
    title: "Good (4.0-5.0)",
    dataIndex: "good_count",
    key: "good_count",
    width: "10%",
    align: 'center' as align,
    className: "column__good",
    sorter: (a: BranchRatingSummary, b: BranchRatingSummary) => a.good_count - b.good_count
  },
  {
    title: "AVG",
    dataIndex: "avg_rating",
    key: "avg_rating",
    width: "10%",
    align: 'center' as align,
    sorter: (a: BranchRatingSummary, b: BranchRatingSummary) => a.avg_rating - b.avg_rating
  }
];

class Table extends React.Component<IProps, {}> {
  render() {
    return (
      <Wrapper>
        <AntdTable
          dataSource={this.props.branches}
          columns={columns}
          pagination={{ pageSize: 15 }}
          size={"middle"}
        />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  .ant-table-thead {
    .column__good {
      background-color: #90ee90;
    }
    .column__medium {
      background-color: #ffa500;
    }
    .column__bad {
      background-color: #ff6347;
    }
  }
`;

export default Table;
