import React from 'react'
import { Table as AntdTable, Button, Col, Row } from 'antd'
import axios from 'axios'
import moment from 'moment'
import styled from 'styled-components'
import { LOCAL_HOST, INTER_HOST } from '../../config'

interface Booking {
  id: number
  booking_begin: string
  booking_end: string,
  branch_id: number
  branch_name: string,
  dealer_th: string,
  branch_phone_number: string
  car_id: number
  car_description: string
  first_name: string
  last_name: string
  email: string
  phone_number: string
  note: string
  location_id: number
  location_name_th: string
  current_state: string
  source: string
  created_at: string
  updated_at: string
  is_dealer_not_contact: boolean
}
interface State {
  list: Booking[]
  enablePulling: boolean
}
interface Props {
  className?: string
}
let intervalID: any
class Table extends React.Component<Props, State> {
  state = {
    list: [],
    enablePulling: true
  }
  componentDidMount() {
    this.getBookings()
    this.startPulling()
  }
  componentWillUnmount() {
    this.clearPulling()
  }
  togglePulling = () => {
    if (this.state.enablePulling) {
      this.clearPulling()
    } else {
      this.startPulling()
    }
    this.setState({ enablePulling: !this.state.enablePulling })
  }
  clearPulling = () => clearInterval(intervalID)
  startPulling = () => {
    intervalID = setInterval(this.getBookings, 5000)
  }
  getBookings = () => {
    axios.get('/api/v1/bookings')
      .then(({ data }) => this.setState({ list: data }))
  }

  render() {
    return (
      <div className={this.props.className}>
        <Row type="flex" justify="space-between" align="middle">
          <Col>
          </Col>
          <Col>
            <Button onClick={this.togglePulling}>
              {this.state.enablePulling ? 'STOP PULLING' : 'START PULLING'}
            </Button>
          </Col>
        </Row>

        <AntdTable
          columns={[
            {
              title: 'Created',
              dataIndex: 'created_at',
              render: r => moment(r, 'YYYY-MM-DD HH:mm').format('YYYY/MM/DD HH:mm')
            },
            {
              title: 'State',
              dataIndex: 'current_state',
            }, {
              title: 'Booking ID',
              render: (row: Booking) => {
                const url = ['local', 'original'].includes(row.source) ? `${LOCAL_HOST}/administration/car_bookings` : `${INTER_HOST}/admin/bookings/booking`
                return (
                  <a 
                      href={`${url}/${row.id}`} 
                      target="_blank" 
                      rel="noopener noreferrer"
                  >
                    {row.id}
                  </a>
                )
              }
            }, {
              title: 'Booking Begin',
              dataIndex: 'booking_begin',
              render: r => moment(r).format('YYYY/MM/DD HH:mm')
            }, {
              title: 'Booking End',
              dataIndex: 'booking_end',
              render: r => moment(r).format('YYYY/MM/DD HH:mm')
            },
            {
              title: 'Car',
              render: (row: Booking) => {
                if (row.source === 'local') {
                  return <a 
                    target="_blank" 
                    href={`${LOCAL_HOST}/th/administration/cars/${row.car_id}`}
                    rel="noopener noreferrer"
                  >
                    {row.car_description}
                  </a>
                }
                return row.car_description
              }
            }, {
              title: 'Dealer',
              render: (row: Booking) => {
                if (row.source === 'local') {
                  return <a 
                    target="_blank" 
                    href={`${LOCAL_HOST}/th/administration/branches/${row.branch_id}`}
                    rel="noopener noreferrer"
                  >
                    {row.dealer_th}
                  </a>
                }
                return row.dealer_th
              },
            }, {
              title: 'Customer',
              render: (row: Booking) => (
                <div>
                  <div>{row.first_name} {row.last_name}</div>
                  <div>{row.phone_number}</div>
                </div>
              ),
            }, {
              title: 'Location',
              dataIndex: 'location_name_th'
            }, {
              title: 'Note',
              dataIndex: 'note',
              render: r => r.split('\n').map((x: string) => <div key={x}>{x}</div>),
            }]}
          rowClassName={(row: Booking) => {
            if (row.is_dealer_not_contact) return 'report once'
            return 'nomal'
          }}
          rowKey="id"
          dataSource={this.state.list}
        />
      </div>
    )
  }
}

export default styled(Table)`
.once {
  background: #ccc;
}
.twice {
  background: yellow;
}
.triple {
  background: red;
}
.abnormal {
  background: purple;
}

.report__example {
  display: flex;
  > div {
    margin-right: 20px;
    display: flex;
  }
  span {
    display: block;
    width: 40px;
    height: 20px;
  }
}
`